export class PostResponse {
    success: boolean = false;
    message: string = "";
};

export class SelectOption {
    value: string = "";
    label: string = "";
    constructor(_value, _label) {
        this.value = _value;
        this.label = _label;
    }
};

export class SelectOptions {
    options: SelectOption[] = [];
    hasLoaded: boolean = false;
}

export class AlertData {
    consumerAlertId: number = 0;
    consumerAlertTemplateDescription: string = "";
    alertText: string = "";
    createdDateUTC: string = "";
    isRead: boolean = false;
    keyData: string = "";
    isExpanded: boolean = false;
};

export class AlertsData {
    recordCount: number = 0;
    refreshDate: Date = new Date();
    searchResults: AlertData[] = [];
};

export class AlertsFilter {
    isRead: boolean|null = null;
};