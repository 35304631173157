import React, { useContext, useEffect, useState } from 'react';
import { AuthContext, ProfileContext }  from '../../App';
import { useTranslation } from 'react-i18next';
import { renderColumnHeader, useTable, usePagination, useSortBy, useExpanded } from '../Grid';
import { PostResponse } from '../Models';
import { ErrorNotification, SuccessNotification } from '../Notification';
import { handleGetFileResponse }  from '../../util/File';
import { formatDate } from '../../util/Format';
import {get, deleete} from '../../util/HttpRequest'
import { Button, Modal } from 'antd';
import Waiting from '../Waiting';
import GridSummary from '../GridSummary';
import GridPagination from '../GridPagination';
import ClaimDocumentUpload from './DocumentUpload'
import { UploadIcon, DeleteIcon, ExclamationIcon, FileIcon, FilePdfIcon } from '../Icons'

class ClaimDocumentData {
    processStatus: string = "";
    documentId: number = 0;
    claimId: number = 0;
    documentDateUTC: string = "";
    description: string = "";
    fileExtension: string = "";
    fileContentType: string = "";
    processMessage: string = "";
    showDocument: boolean = false;
    allowDelete: boolean = false;
    matchesFilter: boolean = true;
};

class ClaimDocumentsData {
    recordCount: number = 0;
    refreshDate: Date = new Date();
    searchResults: ClaimDocumentData[] = [];
};

class ClaimDocumentsFilter {
    processStatus: string[] = [];
};

function ClaimDocuments(props) {
    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");
    const [gridFilter, setGridFilter] = useState(new ClaimDocumentsFilter());
    const [gridData, setGridData] = useState(new ClaimDocumentsData());
    const {accessToken, extendSession, user} = useContext(AuthContext);
    const {languagePreference, userProfileLossDraftsClaim} = useContext(ProfileContext);
    const {t} = useTranslation(["ClaimDocuments", "DataGrid", "Form"]);
    const { confirm } = Modal;
    const columnHeaderProps = [
        {
            id: 'documentDateUTC', className: 'datagrid-header-left', sortedDescLabel: t('DataGrid:lblSortedDescending'), sortedAscLabel: t('DataGrid:lblSortedAscending'), unsortedLabel: t('DataGrid:lblUnsorted')
        },
        {
            id: 'description', className: 'datagrid-header-left', sortedDescLabel: t('DataGrid:lblSortedDescending'), sortedAscLabel: t('DataGrid:lblSortedAscending'), unsortedLabel: t('DataGrid:lblUnsorted')
        },
        {
            id: 'processMessage', className: 'datagrid-header-left', sortedDescLabel: t('DataGrid:lblSortedDescending'), sortedAscLabel: t('DataGrid:lblSortedAscending'), unsortedLabel: t('DataGrid:lblUnsorted')
        }
    ];

    useEffect (() => {
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languagePreference, userProfileLossDraftsClaim.claimId]);
    
    function refreshData() {
        setLoading(true);
        getData();
    };

    function setProcessStatusFilter(processStatus) {
        const filter = {...gridFilter, processStatus: processStatus};
        setGridFilter(filter);
        applyFilters(gridData.searchResults, filter);
    };
    
    function applyFilters(data, filter: ClaimDocumentsFilter) {
        if (filter.processStatus.length === 0) {
            data.forEach(d => {
                d.matchesFilter = true;
            });
        }
        else {
            data.forEach(d => {
                d.matchesFilter = (filter.processStatus.includes(d.processStatus));
            });
        }
        return data;
    };

    function getData() {
        extendSession();
        
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/ClaimDocuments");
        url.searchParams.set("UserId", user.id);
        url.searchParams.set("ClaimId", userProfileLossDraftsClaim.claimId.toString());

        console.log("Fetch Claim Documents from " + url);

        get(url, accessToken)
        .then( response => {
            if (!response.ok) { throw response }
            return response.json() as Promise<ClaimDocumentData[]>
        })
        .then( searchResults => {
            const filteredSearchResults = applyFilters(searchResults, gridFilter);
            const data = {
                recordCount: filteredSearchResults.length, 
                refreshDate: new Date(),
                searchResults: filteredSearchResults
            };
            setGridData(data);
            setErrorMsg("");
            setLoading(false);
        })
        .catch( error => {
            setGridData(new ClaimDocumentsData());
            setErrorMsg(error.message);
            setLoading(false);
        })
    };

    function handleDocumentUpload() {
        extendSession();
        props.showUploadDocumentModal();
    }

    function getDocumentFromAPI(processStatus, documentId, isDownload) {
        extendSession();
        
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/ClaimDocumentFile");
        url.searchParams.set("UserId", user.id);
        url.searchParams.set("ClaimId", userProfileLossDraftsClaim.claimId.toString());
        url.searchParams.set("ProcessStatus", processStatus);
        url.searchParams.set("DocumentId", documentId.toString());

        console.log("Fetch Claim Document File from " + url);
        
        get(url, accessToken)
        .then((response) => {
            handleGetFileResponse(response, isDownload);
        })
        .catch( error => {
            setErrorMsg(error.message);
        });
    };

    function viewDocument(event, processStatus, documentId) {
        event.preventDefault();
        getDocumentFromAPI(processStatus, documentId, false);
    };

    function deleteDocumentConfirm(event, processStatus, documentId) {
        event.preventDefault();

        confirm({
            autoFocusButton: 'cancel',
            cancelButtonProps: {type: "default"},
            cancelText: t('Form:btnCancel'),
            icon: <ExclamationIcon class="medium-icon color-red" />,
            maskClosable: true,
            okButtonProps: {className: "button submit"},
            okText: t('actionDelete'),
            onOk() {
              deleteDocumentConfirmOK(processStatus, documentId)
            },
            title: t('confirmDelete')
          });
    }

    function deleteDocumentConfirmOK(processStatus, documentId) {
        deleteDocument(processStatus, documentId);
    }
    
    function deleteDocument(processStatus, documentId) {
        extendSession();
        
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/ClaimDocument");
        url.searchParams.set("UserId", user.id);
        url.searchParams.set("ClaimId", userProfileLossDraftsClaim.claimId.toString());
        url.searchParams.set("ProcessStatus", processStatus);
        url.searchParams.set("DocumentId", documentId.toString());

        console.log("Delete Claim Document at " + url);
    
        deleete(url, accessToken)
        .then((response) => {
            if (!response.ok) { throw new Error(response.status + " - " + response.statusText)}
            return response.json() as Promise<PostResponse>;
        })
        .then(response => {
            if (response.success) {
                refreshData();
                SuccessNotification(t('deleteSuccessTitle'), t('deleteSuccessBody'));
            }
            else {
                ErrorNotification(t('deleteFailureTitle'), t('deleteFailureBody'));
            }
        })
        .catch(error => {
            ErrorNotification(t('deleteFailureTitle'), t('deleteFailureBody'));
        });
    };

    function Table({ columns, data }) {
        const pageSize = Number(import.meta.env.VITE_GRID_PAGE_SIZE);
        // Use the state and functions returned from useTable to build your UI
        const {
          getTableProps,
          getTableBodyProps,
          headerGroups,
          //rows,
          prepareRow,
          //visibleColumns,
          page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
          canPreviousPage,
          canNextPage,
          // pageOptions,
          pageCount,
          gotoPage,
          nextPage,
          previousPage,
          //setPageSize,
          state: { pageIndex/*, pageSize, expanded*/ },
        } = useTable({
            columns,
            data,
            disableSortRemove: true,
            initialState: { 
                pageIndex: 0,
                pageSize: pageSize
                },
            },
            useSortBy,
            useExpanded, // We can useExpanded to track the expanded state for sub components too!
            usePagination
        )

        const filters = <div>
            <Button className="button filter" disabled={gridFilter.processStatus.includes("ETRM") || gridFilter.processStatus.includes("UPLD")} onClick={() => setProcessStatusFilter(["ETRM", "UPLD"])}>{t('filterAwaitingReview')}</Button>
            <Button className="button filter" disabled={gridFilter.processStatus.includes("ACPT")} onClick={() => setProcessStatusFilter(["ACPT"])}>{t('filterAccepted')}</Button>
            <Button className="button filter" disabled={gridFilter.processStatus.includes("RJUP") || gridFilter.processStatus.includes("RJDS")} onClick={() => setProcessStatusFilter(["RJUP", "RJDS"])}>{t('filterRejected')}</Button>
            <Button className="button filter" disabled={gridFilter.processStatus.length === 0} onClick={() => setProcessStatusFilter([])}>{t('filterAll')}</Button>
        </div>;
        
        const pagination = <GridPagination canPreviousPage={canPreviousPage} canNextPage={canNextPage} 
            pageIndex={pageIndex} pageCount={pageCount} gotoPage={gotoPage} previousPage={previousPage}
            nextPage={nextPage} />;
        
        const summary = <GridSummary pageIndex={pageIndex} pageCount={pageCount} pageSize={pageSize} recordCount={data.length} 
            recordType={t('gridRecordType', {count: data.length})} refreshDate={gridData.refreshDate} pagination={pagination} />;

        return (
        <>
            {filters}
            <br />
            {summary}
            <table className="table" role="presentation" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => {
                        let { ['key']: headerGroupPropsKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
                        return (
                            <tr key={headerGroupPropsKey} {...headerGroupProps}>
                                {headerGroup.headers.map(column => (
                                    renderColumnHeader(column, columnHeaderProps.find(x => x.id === column.id))
                                ))}
                            </tr>
                        );
                    })}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                prepareRow(row)
                return (
                    <React.Fragment key={{...row.getRowProps()}.key}>
                    <tr className={"row-" + (i % 2 === 0 ? "even" : "odd")}>
                        {row.cells.map(cell => {
                            let { ['key']: cellPropsKey, ...cellProps } = cell.getCellProps();
                            return (    
                                <td key={cellPropsKey} {...cellProps}>{cell.render('Cell')}</td>
                            )
                        })}
                    </tr>
                    </React.Fragment>
                )
                })}
            </tbody>
            </table>
        </>
        )
    }

    function renderDataGrid() {
        const columns = [{
            id: 'actions',
            sortable: false,
            Cell: row => (
                <div className="datagrid-data-center">
                {
                    row.row.original.showDocument && 
                    (
                        <Button type="link" className="icon-button" title={t('actionView')} 
                            onClick={(e) => viewDocument(e, row.row.original.processStatus, row.row.original.documentId)}
                        >
                             { 
                                row.row.original.fileExtension === "PDF" && (
                                    <FilePdfIcon title={t('actionView')} />
                                )
                            }
                            { 
                                row.row.original.fileExtension !== "PDF" && (
                                    <FileIcon title={t('actionView')} />
                                )
                            }
                        </Button>
                    )
                }
                {
                    !row.row.original.showDocument && 
                    (<span className="action-icon" />)
                }
                {
                    row.row.original.allowDelete && 
                    (
                        <Button type="link" className="icon-button" title={t('actionDelete')} 
                            onClick={(e) => deleteDocumentConfirm(e, row.row.original.processStatus, row.row.original.documentId)}
                        >
                            <DeleteIcon title={t('actionDelete')} />
                        </Button>
                    )
                }
                {
                    !row.row.original.allowDelete && 
                    (<span className="action-icon" />)
                }
                </div>
            )
        }, {
            Header: () => (<>{t('columnDate')}</>),
            accessor: columnHeaderProps.find(x => x.id === 'documentDateUTC')?.id,
            canSort: true,
            Cell: row => (
                <div className="datagrid-data-left">
                {
                    formatDate(row.value)
                }
                </div>
            )
        }, {
            Header: () => (<>{t('columnDescription')}</>),
            accessor: columnHeaderProps.find(x => x.id === 'description')?.id,
            canSort: true
        }, {
            Header: () => (<>{t('columnStatus')}</>),
            accessor: columnHeaderProps.find(x => x.id === 'processMessage')?.id,
            canSort: true
        }];
       
        let table = <Table
            columns={columns}
            data={gridData.searchResults.filter(item => item.matchesFilter)} 
        />;

        let modal = props.documentTypeSelectOptions.hasLoaded && props.documentUploadModalShow &&
        (
            <ClaimDocumentUpload 
                documentTypeSelectOptions={props.documentTypeSelectOptions}
                refreshData={refreshData} 
                hideModal={props.hideUploadDocumentModal}
            />
        );

        return <div>{table}{modal}</div>;
    };

    let title = <>
        <h2>{t("title")}</h2>
        {
            !errorMsg && !loading && props.documentTypeSelectOptions.hasLoaded &&
            (
                <span>
                    <Button 
                        className="button submit" 
                        icon={<UploadIcon class="button-icon" title={t('actionUpload')} />}
                        type="primary"
                        onClick={() => handleDocumentUpload()}>
                            {t('actionUpload')}
                    </Button>
                </span>

            )
        }
        <br /><br />
    </>;
    let contents = (errorMsg > '') ? <p><em>{errorMsg}</em></p> : loading ? <Waiting size='large' /> : renderDataGrid();
    return <div>{title}<br />{contents}</div>
};

export default ClaimDocuments;
