import { Spin } from 'antd';

function Waiting (props) {
    const size = props.size > '' ? props.size : 'default';

    return (
        <Spin size={size} delay={250} />
    );
}

export default Waiting;