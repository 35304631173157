import {notification} from 'antd';
import type { NotificationPlacement } from 'antd/es/notification/interface';

const NOTIFICATION_PLACEMENT: NotificationPlacement = "topLeft";

export function SuccessNotification(title: string, body: string) {
    notification.success({
        description: body,
        message: title,
        placement: NOTIFICATION_PLACEMENT
    });
}

export function ErrorNotification(title: string, body: string) {
    notification.error({
        description: body,
        message: title,
        placement: NOTIFICATION_PLACEMENT
    });
}