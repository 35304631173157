import { useTranslation } from 'react-i18next';
//import {formatDateTime} from '../util/Format';

function GridSummary(props) {
    const { t } = useTranslation("DataGrid");
    
    function getDisplayCountText() {
        if (props.recordCount > props.pageSize) {
            return t("lblDisplayCountWithPaging", {
                pageIndex: props.pageIndex + 1,
                pageCount: props.pageCount,
                minRecord: (props.pageIndex * props.pageSize) + 1,
                maxRecord: Math.min(((props.pageIndex + 1) * props.pageSize), props.recordCount),
                recordCount: props.recordCount, 
                recordType: props.recordType
            });
        }
        else {
            return t("lblDisplayCountWithoutPaging", {
                pageIndex: props.pageIndex + 1,
                pageCount: props.pageCount === 0 ? 1 : props.pageCount,
                recordCount: props.recordCount, 
                recordType: props.recordType
            });
        }
    }
    
    return <div className="column-container space-between">
        <span>{getDisplayCountText()}</span>
        {/* {
            <span>
                {
                    t("lblLastRefreshedAt")
                }&nbsp;
                {
                    formatDateTime(props.refreshDate)
                }&nbsp;
            </span>
        } */}
        {
            props.pagination
        }
    </div>;
};

export default GridSummary;
