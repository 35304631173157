import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext, ProfileContext }  from '../../App';
import { useTranslation } from 'react-i18next';
import Waiting from '../Waiting';
import {SelectOption, SelectOptions} from '../Models'
import {CacheKey} from '../../util/Cache'
import {formatPhone, formatPin, formatAddressMultiLine} from '../../util/Format';
import {get} from '../../util/HttpRequest'
import RegistrationUpdate from '../Claim/RegistrationUpdate';
import { Button } from 'antd';
import { EditIcon } from '../Icons'

export class StagingClaimAddress {
    addressLine1: string = "";
    addressLine2: string = "";
    city: string = "";
    countryISO: string = "US";
    postalCode: string = "";
    state: string = "";
};

export class StagingClaimData {
    activityStatusCode: string = "A";
    claimNumber: string = "";
    encodedClientId: string = "";
    hasLoaded: boolean = false;
    insuredName: string = "";
    lD_StagingClaimID: number = 0;
    loanNumber: string = "";
    mailingAddress: StagingClaimAddress = new StagingClaimAddress();
    mailingAddressSameAsPropertyAddress: boolean = false;
    modified_Date: Date|null = null;
    modifiedDateUTC: string = "";
    pin: number|null = null;
    propertyAddress: StagingClaimAddress = new StagingClaimAddress();
    ssoUserId: string = "";
};

function ClaimRegistration(props) {
    const [updateRegistrationModal, setUpdateRegistrationModal] = useState(false);
    const [stagingClaimData, setStagingClaimData] = useState(new StagingClaimData());
    const [countrySelectOptions, setCountrySelectOptions] = useState(new SelectOptions());
    const [usStateSelectOptions, setUsStateSelectOptions] = useState(new SelectOptions());
    const {accessToken, extendSession, user} = useContext(AuthContext);
    const {languagePreference, refreshUserProfile, userProfile} = useContext(ProfileContext);
    const { t } = useTranslation(["ClaimRegistration", "Profile"]);
    
    const [refreshCount, setRefreshCount] = useState(0);
    const refreshCountRef = useRef(refreshCount);
    const intervalRef = useRef(setTimeout(() => {}, 0));
    const refreshTimeoutMilliseconds = 10000;

    useEffect (() => {
        getRegistrationData();
        
        return () => {
            // executes at component unmount
            cleanupTimeout();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languagePreference]);

    function cleanupTimeout() {
        if (intervalRef.current !== null) {
            clearTimeout(intervalRef.current);
        }
    }

    function toggleUpdateRegistrationModal(show: boolean) {
        extendSession();
        setUpdateRegistrationModal(show);
    }
    
    function getRegistrationData(extend: boolean = true) {
        if (extend) {
            extendSession();
            refreshCountRef.current = 0;
            setRefreshCount(refreshCountRef.current);
        }

        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/StagingClaim");
        url.searchParams.set("UserId", user.id);
        console.log("Fetch StagingClaim data from " + url);

        get(url, accessToken)
        .then( response => {
            if (!response.ok) { throw response }
            return response.json() as Promise<StagingClaimData>
        })
        .then( data => {
            if (!data) {
                // If user is logged in already while their staging claim review is completed, they won't have an
                // open staging claim anymore, and this page will want to create a new staging claim. To prevent
                // this, save a flag when they submit a staging claim, and anytime after that if it looks like they
                // don't have a staging claim, refresh their profile and reload the page to get latest claim info.
                if ("true" === localStorage.getItem(CacheKey.LOSS_DRAFTS_STAGING_CLAIM_SUBMITTED)) {
                    localStorage.removeItem(CacheKey.LOSS_DRAFTS_STAGING_CLAIM_SUBMITTED);
                    refreshUserProfile();
                    window.location.reload();
                }
                
                data = new StagingClaimData();
                data.ssoUserId = user.id;

                let clientId = localStorage.getItem(CacheKey.CLIENT_ID);
                if (clientId && clientId > "") {
                    data.encodedClientId = clientId;
                }
            }
            else {
                data.modified_Date = new Date(Date.parse(data.modifiedDateUTC));
            }
            data.mailingAddressSameAsPropertyAddress = areAddressesEqual(data.propertyAddress, data.mailingAddress);
            data.hasLoaded = true;
            setStagingClaimData(data);
            loadStateSelectOptions("US");
            loadCountrySelectOptions();
            
            setRefreshCount(++refreshCountRef.current);
            cleanupTimeout();
            if (data.activityStatusCode === 'P'/* && refreshCountRef.current < 10*/) {
                intervalRef.current = setTimeout(() => {
                    getRegistrationData(false);
                }, refreshTimeoutMilliseconds);
            }
        })
        .catch( error => {
            setStagingClaimData(new StagingClaimData());
        })
    };

    function loadStateSelectOptions(country) {
        const cacheValue = sessionStorage.getItem(CacheKey.SELECT_OPTIONS_US_STATE);
        if (cacheValue) {
            console.log("Fetch StateSelectOptions data from cache");
            let s = new SelectOptions();
            s.hasLoaded = true;
            s.options = JSON.parse(cacheValue);
            setUsStateSelectOptions(s);
        }
        else {
            const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/StateSelectOptions");
            url.searchParams.set("Country", country);
            console.log("Fetch StateSelectOptions data from " + url);
            get(url, accessToken)
            .then( response => {
                if (!response.ok) { throw response }
                return response.json() as Promise<SelectOption[]>
            })
            .then( data => {
                sessionStorage.setItem(CacheKey.SELECT_OPTIONS_US_STATE, JSON.stringify(data));
                let s = new SelectOptions()
                s.hasLoaded = true;
                s.options = data;
                setUsStateSelectOptions(s);
            })
            .catch( error => {
                console.log("Unable to load US states");
            })
        }
    };

    function loadCountrySelectOptions() {
        const cacheValue = sessionStorage.getItem(CacheKey.SELECT_OPTIONS_COUNTRY);
        if (cacheValue) {
            console.log("Fetch CountrySelectOptions data from cache");
            let c = new SelectOptions();
            c.hasLoaded = true;
            c.options = JSON.parse(cacheValue);
            setCountrySelectOptions(c);
        }
        else {
            const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/CountrySelectOptions");
            console.log("Fetch CountrySelectOptions data from " + url);
            get(url, accessToken)
            .then( response => {
                if (!response.ok) { throw response }
                return response.json() as Promise<SelectOption[]>
            })
            .then( data => {
                sessionStorage.setItem(CacheKey.SELECT_OPTIONS_COUNTRY, JSON.stringify(data));
                let c = new SelectOptions();
                c.hasLoaded = true;
                c.options = data;
                setCountrySelectOptions(c);
            })
            .catch( error => {
                console.log("Unable to load countries");
            });
        }
    };

    function areAddressesEqual(m, p) : boolean {
        return (m.addressLine1.trim() === p.addressLine1.trim() &&
            m.addressLine2.trim() === p.addressLine2.trim() &&
            m.city.trim() === p.city.trim() &&
            m.state.trim() === p.state.trim() &&
            m.postalCode.trim() === p.postalCode.trim() &&
            m.countryISO.trim() === p.countryISO.trim());
    }

    function isStagingClaimNew() {
        return stagingClaimData.lD_StagingClaimID === 0;
    };

    function isStagingClaimEditable() {
        return stagingClaimData.activityStatusCode === 'A';
    };

    function isStagingClaimLocked() {
        return stagingClaimData.activityStatusCode === 'L';
    };

    function isStagingClaimPending() {
        return stagingClaimData.activityStatusCode === 'P';
    };

    function getCountryDisplayValue(country) {
        if (country === "US") {
            return "";
        }
        return countrySelectOptions.options.find(x => x.value === country)?.label ?? "";
    }

    function getConsentToESignatureDisplayValue() {
        return userProfile.consentToESignature ? t('Profile:lblESignatureConsentYes') : t('Profile:lblESignatureConsentNo');
    };

    function getStatusDisplayValue() {
        let snippet;
        if (isStagingClaimNew()) {
            snippet = <>{t("lblStatusNew")}</>;
        }
        else if (isStagingClaimPending()) {
            snippet = <>{t("lblStatusPending") + ' '}<Waiting size="small" /></>;
        }
        else if (isStagingClaimEditable()) {
            snippet = <>{t("lblStatusEditable")}</>;
        }
        else if (isStagingClaimLocked()) {
            snippet = <>{t("lblStatusLocked")}</>;
        }
        else {
            snippet = <>{t("lblStatusRejected")}</>;
        }
        return snippet;
    }

    function getStatusInstructionsDisplayValue() {
        let snippet;
        if (isStagingClaimNew()) {
            snippet = <>{t("lblStatusNewInstructions")}</>;
        }
        else if (isStagingClaimPending()) {
            snippet = <>{t("lblStatusPendingInstructions") + ' '}<Waiting size="small" /></>;
        }
        else if (isStagingClaimEditable()) {
            snippet = <>{t("lblStatusEditableInstructions")}</>;
        }
        else if (isStagingClaimLocked()) {
            snippet = <>{t("lblStatusLockedInstructions")}</>;
        }
        else {
            snippet = <>{t("lblStatusRejectedInstructions")}</>;
        }
        return snippet;
    }

    function renderData() {
        return (
            <div>
                <div className="row-container width-medium">
                <div className="column-container">
                        <div className="key" >{t('lblPropertyAddress')}</div>
                        <div className="value multiline" >
                            {formatAddressMultiLine(
                                [stagingClaimData.propertyAddress.addressLine1, stagingClaimData.propertyAddress.addressLine2], 
                                [stagingClaimData.propertyAddress.city, stagingClaimData.propertyAddress.state, stagingClaimData.propertyAddress.postalCode],
                                [getCountryDisplayValue(stagingClaimData.propertyAddress.countryISO)]
                            )}
                        </div>
                    </div>
                    <div className="column-container">
                        <div className="key even" >{t('lblMailingAddress')}</div>
                        <div className="value multiline even" >
                            {
                                stagingClaimData.mailingAddressSameAsPropertyAddress && (
                                    t("lblMailingAddressSameAsPropertyAddress")
                                )
                            }
                            {
                                !stagingClaimData.mailingAddressSameAsPropertyAddress &&
                                countrySelectOptions.hasLoaded && (
                                    formatAddressMultiLine(
                                        [stagingClaimData.mailingAddress.addressLine1, stagingClaimData.mailingAddress.addressLine2], 
                                        [stagingClaimData.mailingAddress.city, stagingClaimData.mailingAddress.state, stagingClaimData.mailingAddress.postalCode],
                                        [getCountryDisplayValue(stagingClaimData.mailingAddress.countryISO)]
                                    )
                                )
                            }
                        </div>
                    </div>
                    <div className="column-container">
                        <div className="key" >{t('lblInsuredName')}</div>
                        <div className="value" >{stagingClaimData.insuredName}</div>
                    </div>
                    <div className="column-container">
                        <div className="key even" >{t('lblPin')}</div>
                        <div className="value even" >{formatPin(stagingClaimData.pin)}</div>
                    </div>
                    <div className="column-container">
                        <div className="key" >{t('lblLoanNumber')}</div>
                        <div className="value" >{stagingClaimData.loanNumber}</div>
                    </div>
                    <div className="column-container">
                        <div className="key even" >{t('lblClaimNumber')}</div>
                        <div className="value even" >{stagingClaimData.claimNumber}</div>
                    </div>
                    <div className="column-container">
                        <div className="key" >{t('lblMobilePhone')}</div>
                        <div className="value" >{formatPhone(null, null, userProfile.mobilePhone, null)}</div>
                    </div>
                    { 
                        userProfile.allowESignature && 
                        (
                            <div className="column-container">
                                <div className="key even" >{t('Profile:lblESignatureConsent')}</div>
                                <div className="value even" >{getConsentToESignatureDisplayValue()}</div>
                            </div>
                        )
                    }
                    <div className="column-container">
                        <div className={userProfile.allowESignature ? "key" : "key even"} >{t('lblStatus')}</div>
                        <div className={userProfile.allowESignature ? "value multiline" : "value multiline even"} >{getStatusDisplayValue()}</div>
                    </div>
                </div>
                <div>
                    <br />
                    {getStatusInstructionsDisplayValue()}
                </div>
            </div>
        );
    };

    let title = <>
        <h2>{t('title')}</h2>
        {
            stagingClaimData.hasLoaded && isStagingClaimEditable() && usStateSelectOptions.hasLoaded && countrySelectOptions.hasLoaded &&
            (
                <span>
                    <Button 
                        className="button submit" 
                        tabIndex={0}
                        icon={<EditIcon class="button-icon" title= {t('lblUpdate')} />}
                        type="primary"
                        onClick={() => toggleUpdateRegistrationModal(true)}>
                            {t('lblUpdate')}
                    </Button>
                </span>
            )
        }
        <br /><br />
    </>;
    
    let contents = !stagingClaimData.hasLoaded ? <Waiting size='large' /> : renderData();
    return <div>
            {title}
            <br />
            {contents}
            {
                stagingClaimData.hasLoaded && isStagingClaimEditable() && usStateSelectOptions.hasLoaded && countrySelectOptions.hasLoaded && 
                updateRegistrationModal &&
                (
                    <RegistrationUpdate 
                        hideModal={() => toggleUpdateRegistrationModal(false)}
                        refreshData={getRegistrationData} 
                        stagingClaimData={stagingClaimData}
                        countrySelectOptions={countrySelectOptions}
                        usStateSelectOptions={usStateSelectOptions}
                        allowSuccessNotification={true}
                    />
                )
            }
        </div>;
};

export default ClaimRegistration;
