import { useContext, useState } from 'react';
import { AuthContext, ProfileContext }  from '../App';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import { PostResponse } from './Models';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { putForm } from '../util/HttpRequest';

function EmailUpdate(props) {
    const [saveErrorMsg, setSaveErrorMsg] = useState("");
    const {accessToken, extendSession, user} = useContext(AuthContext);
    const {userProfile} = useContext(ProfileContext);
    const { t } = useTranslation(["EmailUpdate", "Form"]);

    const ValidationSchema = Yup.object().shape({
        currentEmail: Yup.string()
            .required(t('currentEmailRequired'))
            .matches(new RegExp("^" + userProfile.email + "$", "i"), t('currentEmailInvalid')),
        newEmail: Yup.string()
            .required(t('newEmailRequired'))
            .email(t('newEmailInvalid'))
    });

    function handleSubmit(values, setSubmitting) {
        setSubmitting(false);   // MUST invoke this before updating any parent props or the form submit happens twice!
        extendSession();
        
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/ChangeEmail");
        const formData = new FormData();
        formData.append("UserId", user.id);
        formData.append("CurrentEmail", values.currentEmail);
        formData.append("NewEmail", values.newEmail);
        console.log("Put ChangeEmail to " + url);

        putForm(url, accessToken, formData)
        .then((response) => {
            if (!response.ok) { throw new Error(response.status + " - " + response.statusText)}
            return response.json() as Promise<PostResponse>;
        })
        .then(response => {
            if (response.success) {
                handleTransition();
            }
            else {
                setSaveErrorMsg(t(response.message.charAt(0).toLowerCase() + response.message.substring(1)));
            }
        })
        .catch(() => {
            setSaveErrorMsg(t('saveError'));
        })
    };

    function handleTransition() {
        props.toggleUpdateEmailModal(false);
        props.toggleVerifyEmailModal(true);
    };

    function renderForm() {
        return (
            <Formik
                initialValues ={{
                    currentEmail: "",
                    newEmail: ""
                }}
                validationSchema={ValidationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values, setSubmitting); 
                }}
            >
            {   
                ({ values, isSubmitting, handleBlur, setFieldValue, submitForm }) => (
                    <Form>
                        <label htmlFor="currentEmail">{t('lblCurrentEmail')}</label>
                        <br />
                        <Field id="currentEmail" name="currentEmail" type="text" className="input width-input-large" placeholder={t('lblCurrentEmail')} data-lpignore="true" />
                        <br />
                        <ErrorMessage name="currentEmail" component="div" className="error" />
                        <br />
                        <label htmlFor="newEmail">{t('lblNewEmail')}</label>
                        <br />
                        <Field id="newEmail" name="newEmail" type="text" className="input width-input-large" placeholder={t('lblNewEmail')} onBlur={handleBlur} data-lpignore="true" />
                        <br />
                        <ErrorMessage name="newEmail" component="div" className="error" />
                        <br />
                        <Button type="default" className="button" onClick={() => props.toggleUpdateEmailModal(false)}>{t('Form:btnCancel')}</Button>
                        &nbsp;&nbsp;
                        <Button type="primary" className="button submit" disabled={isSubmitting} onClick={submitForm}>{t('btnSubmit')}</Button>
                        <br /><br />
                        <div className="error">{saveErrorMsg}</div>
                    </Form>
                )
            }
            </Formik>
        );
    }

    return (
        <Modal open={true} 
               title={t('title')} 
               footer={null}
               onCancel={() => props.toggleUpdateEmailModal(false)}
               centered={true}
               destroyOnClose={true}
        >
            <hr />
            {t('lblInstructions')}
            <br /><br />
            {renderForm()}
            <br />
            <Button type="text" className="link-button" title={t('btnTransitionToNextStep')}
                    onClick={handleTransition}
            >
                {t('btnTransitionToNextStep')}
            </Button>
        </Modal>
    );
}

export default EmailUpdate;
