import React from 'react';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation('Layout');
  const copyright = "\u00A9 " + new Date().getFullYear() + "  Proctor Loan Protector";// + t('lblCopyright');
  return (
    <div id="footer" className="column-container space-between">
      <span>
        <ul>
          <li>
            <a href="https://www.proctorlp.com" target="_blank" rel="noopener noreferrer" aria-label={copyright}>
              {copyright}
          </a>
          </li>
        </ul>
      </span>
      <span>
        <ul>
          <li>
            <a href="https://www.bbinsurance.com/privacy-statement" target="_blank" rel="noopener noreferrer" aria-label={t('lblPrivacy')}>
              {t('lblPrivacy')}
            </a>
          </li>
          <li>
            <a href="https://www.bbinsurance.com/legal-notices" target="_blank" rel="noopener noreferrer" aria-label={t('lblLegal')}>
              {t('lblLegal')}
            </a>
          </li>
          <li>
            <span>
               v{import.meta.env.VITE_SITE_VERSION}
            </span>
          </li>
        </ul>
      </span>
    </div>
  );
}

export default Footer;