import { useContext, useEffect, useState } from 'react';
import { AuthContext, ProfileContext }  from '../../App';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import { SuccessNotification } from '../Notification';
import { setUnreadAlertCountLabelFromApi } from '../../util/AlertCountHack';
import {get, postForm} from '../../util/HttpRequest'
import ProfileUpdate from '../ProfileUpdate';
import EmailUpdate from '../EmailUpdate';
import EmailVerification from '../EmailVerification';
import { PostResponse } from '../Models';

function UserRequest(props) {
    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");
    const {accessToken, extendSession, user} = useContext(AuthContext);
    const {userProfile } = useContext(ProfileContext);
    const [updateProfileModal, setUpdateProfileModal] = useState(false);
    const [updateEmailModal, setUpdateEmailModal] = useState(false);
    const [verifyEmailModal, setVerifyEmailModal] = useState(false);
    const {userProfileLossDraftsClaim} = useContext(ProfileContext);
    const [userRequestAllowed, setUserRequestAllowed] = useState(false);
    const { t } = useTranslation(["ClaimDetails", "Profile", "Form", "Language"]);

    useEffect (() => {
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfileLossDraftsClaim.claimId]);

    function refreshData() {
        setLoading(true);
        switch(props.userRequestType) {
            case "InspectionRequest": canUserMakeRequest("BINSPREQ"); break;
            default: break;
        }
    };

    function canUserMakeRequest(eventCategoryCode) {
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/CanClaimUserMakeRequest");
        url.searchParams.set("UserId", user.id);
        url.searchParams.set("ClaimId", userProfileLossDraftsClaim.claimId.toString());
        if (eventCategoryCode > "") {
            url.searchParams.set("EventCategoryCode", eventCategoryCode);
        }
        console.log("Fetch User Request Eligibility from " + url);
        
        get(url, accessToken)
        .then( response => {
            if (!response.ok) { throw response }
            return response.json()
        })
        .then( responseData => {
            setUserRequestAllowed(responseData.canUserMakeRequest);
            setLoading(false);
        })
        .catch( error => {
            setUserRequestAllowed(false);
            setLoading(false);
        })
    };

    function renderProfileData() {
        return (
            <>
                <div className="row-container">
                    <div className="column-container">
                        <div className="key" >{t('Profile:lblEmail')}</div>
                        <div className="value">
                            {userProfile.email}
                            &nbsp;
                            <Button type="text" className="link-button" title={t('userRequest.btnUpdateEmail')}
                                onClick={() => toggleUpdateEmailModal(true)}
                            >
                                {t('userRequest.btnUpdateEmail')}
                            </Button>
                        </div>
                    </div>
                    <div className="column-container">
                        <div className="key even">{t('Profile:lblMobilePhone')}</div>
                        <div className="value even">
                            {userProfile.mobilePhone}
                            &nbsp;
                            <Button type="text" className="link-button" title={t('userRequest.btnUpdateProfile')}
                                onClick={() => toggleUpdateProfileModal(true)}
                            >
                                {t('userRequest.btnUpdateProfile')}
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    function renderRequestBody() {
        return (
            <>
                {
                    !userRequestAllowed &&
                    (
                        <>
                            {t('btnClaimRequestBodyPendingRequest')}
                            <br /><br />
                        </>
                    ) 
                }
                <Button type="default" className="button" onClick={() => props.toggleUserRequestModal("")}>{t('Form:btnCancel')}</Button>
                &nbsp;&nbsp;
                <Button type="primary" className="button submit" onClick={() => submitRequest()}
                    disabled={!(userRequestAllowed && userProfile.mobilePhone !== undefined && userProfile.mobilePhone > "")}
                >
                    {t('btnClaim' + props.userRequestType)}
                </Button>
                <br /><br />
                <div className="error">{errorMsg}</div>
            </>
        );
    }

    function renderBody() {
        if (!loading) {
            if (["InspectionRequest"].includes(props.userRequestType)) {
                return renderRequestBody();
            }
        }
        return <></>;
    }

    function toggleUpdateProfileModal(show: boolean) {
        extendSession();
        setUpdateProfileModal(show);
    }

    function toggleUpdateEmailModal(show: boolean) {
        extendSession();
        setUpdateEmailModal(show);
    }

    function toggleVerifyEmailModal(show: boolean) {
        extendSession();
        setVerifyEmailModal(show);
    }

    function submitRequest() {
        extendSession();
        
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/Claim" + props.userRequestType);
        const formData = new FormData();
        formData.append("UserId", user.id);
        formData.append("ClaimId", userProfileLossDraftsClaim.claimId.toString());
        console.log("Submiting claim " + t('btnClaim' + props.userRequestType) + " to " + url);
        
        postForm(url, accessToken, formData)
        .then((response) => {
            if (!response.ok) { throw new Error(response.status + " - " + response.statusText)}
            return response.json() as Promise<PostResponse>;
        })
        .then(response => {
            if (response.success) {
                props.toggleUserRequestModal("");
                SuccessNotification(t('btnClaim' + props.userRequestType + 'SuccessTitle'), t('btnClaim' + props.userRequestType + 'SuccessBody'));
                setUnreadAlertCountLabelFromApi(user, accessToken);
            }
            else {
                setErrorMsg(t('btnClaim' + props.userRequestType + 'Failure'));
            }
        })
        .catch(() => {
            setErrorMsg(t('btnClaim' + props.userRequestType + 'Failure'));
        });
    };

    return (
        <div>
        {
            !updateProfileModal && !updateEmailModal && !verifyEmailModal &&
            (
                <Modal open={true} 
                       title={t('btnClaim' + props.userRequestType)} 
                       footer={null}
                       onCancel={() => props.toggleUserRequestModal("")}
                       centered={true}
                       destroyOnClose={true}
                >
                    <hr />
                    {
                        // (userProfile.mobilePhone !== undefined && userProfile.mobilePhone > "" ) ? t('btnClaimRequestBodyGoodPhone') : t('btnClaimRequestBodyBadPhone')
                        t('btnClaimRequestBodyReviewProfile')
                    }
                    <br /><br />
                    {renderProfileData()}
                    <br /><br />
                    {renderBody()}
                </Modal>
            )
        }
        {
            updateProfileModal &&
            (
                <ProfileUpdate 
                    toggleUpdateProfileModal={toggleUpdateProfileModal}
                />
            )
        }
        {
            updateEmailModal &&
            (
                <EmailUpdate
                    toggleUpdateEmailModal={toggleUpdateEmailModal}
                    toggleVerifyEmailModal={toggleVerifyEmailModal}
                />
            )
        }
        {
            verifyEmailModal &&
            (
                <EmailVerification
                    toggleUpdateEmailModal={toggleUpdateEmailModal}
                    toggleVerifyEmailModal={toggleVerifyEmailModal}
                />
            )
        }
        </div>
    );
};

export default UserRequest;
