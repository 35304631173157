import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Collapse } from 'antd';
import type { CollapseProps } from 'antd';
import { AuthContext, ProfileContext }  from '../App';
import { handleGetFileResponse }  from '../util/File';
import {get} from '../util/HttpRequest'

class FaqData {
  faqId: number = 0;
  displayOrder: number = 0;
  question: string = "";
  answer: string = "";
};

function FaqBody(props) {
    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");
    const [dynamicFaq, setDynamicFaq] = useState<FaqData[]>([]);
    const [activeKeyStaticItem, setActiveKeystaticItem] = useState<string | string[]>('1'); //since the defaultActiveKey =1 for Static Collapse items,the the active key is also 1. 
    const [activeKeyDynamicItem, setActiveKeyDynamicItem] = useState<string | string[]>('1'); //since the defaultActiveKey =1 for Dynamic Collapse items,the the active key is also 1. 
    const {accessToken, isAuthenticated, extendSession} = useContext(AuthContext);
    const {languagePreference} = useContext(ProfileContext);
    const { t } = useTranslation('FaqBody');

    useEffect (() => {
      refreshData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languagePreference]);

    function refreshData() {
        setLoading(true);
        getData();
    };

    function getData() {
        if (isAuthenticated)  {
          extendSession();
        }
        
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/FAQs");
        console.log("Fetch FAQs from " + url);

        get(url, isAuthenticated ? accessToken : "")
        .then( response => {
            if (!response.ok) { throw response }
            return response.json() as Promise<FaqData[]>
        })
        .then( searchResults => {
            setDynamicFaq(searchResults);
            setErrorMsg("");
            setLoading(false);
        })
        .catch( error => {
            setDynamicFaq([]);
            setErrorMsg(error.message);
            setLoading(false);
        })
    };

    function getVideoTranscriptFile(videoId) {
      if (isAuthenticated)  {
        extendSession();
      }
      
      const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/VideoTranscriptFile");
      url.searchParams.set("VideoId", videoId);

      console.log("Fetch Video Transcript File from " + url);
      
      get(url, isAuthenticated ? accessToken : "")
      .then((response) => {
          handleGetFileResponse(response, false);
      })
      .catch( error => {
          setErrorMsg(error.message);
      });
    };

    function onChangeStaticItemsCollapse(key: string | string[]) {
      if (Array.isArray(key)) {
        if (key.length === 0) {
          setActiveKeystaticItem([]); // No panel is active
        }
        else {
          const latestKey = key.slice(-1)[0]; // Get the last active key, since the key contains an array of active keys.
          setActiveKeystaticItem([latestKey]); // Only keep the current active key.
        }
      }
    };
    
  function onChangeDynamicItemsCollapse(key: string | string[]) {
    if (Array.isArray(key)) {
      if (key.length === 0) {
        setActiveKeyDynamicItem([]); // No panel is active
      }
      else {
        const latestKey = key.slice(-1)[0]; // Get the last active key, since the key contains an array of active keys.
        setActiveKeyDynamicItem([latestKey]); // Only keep the current active key.
      }
    }
  };

    const dynamicItems: CollapseProps['items'] = loading ? [] : [
      {
        key: '4',
        label: t('faq'),
        children: <Collapse 
            items={
              dynamicFaq.map((item, index) => {
                return {
                  key: index + 1,
                  label: <>{item.question}</>,
                  children: <div><p>{item.answer}</p></div>
                };
              })
            }
            defaultActiveKey={['1']} 
            className='nested-accordion' 
            activeKey={activeKeyDynamicItem} 
            onChange={onChangeDynamicItemsCollapse}
        />
      }
    ];
    
    // We could store formatted HTML in the DB or translation files but then we would have to use dangerouslySetInnerHTML
    // to display it after sanitizing it, and that is a non-starter for performance and vulnerability reasons.
    const staticItems: CollapseProps['items'] = [
      {
        key: '1',
        label: t('guideToLD'),
        children: <div className='row-container flex-start'>
          <div>
            <p>{t('guideToLDP1')}</p>
          </div>
          <div className='column-container'>
            <div>
              <iframe className="faq-video"
                src="https://www.youtube.com/embed/QepF9scQ-hs?si=EptwF0j195ZsuC2C&rel=0"
                title={t('whatIsLD')}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                frameBorder="0"
                allowFullScreen
              >
              </iframe>
              <p>
                &nbsp;
                <Button
                  className="button submit"
                  type="primary"
                  onClick={() => getVideoTranscriptFile("QepF9scQ-hs")}>
                  {t('btnVideoTranscript')}
                </Button>
              </p>
            </div>
            <div className="filler-column">&nbsp;</div>
            <div>
              <iframe className="faq-video"
                src="https://www.youtube.com/embed/4mqz5rT6OwQ?si=--8DoJCyEJQIiKj4&rel=0"
                title={t('LDProcess')}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                frameBorder="0"
                allowFullScreen
              >
              </iframe>
              <p>
                &nbsp;
                <Button
                  className="button submit"
                  type="primary"
                  onClick={() => getVideoTranscriptFile("4mqz5rT6OwQ")}>
                  {t('btnVideoTranscript')}
                </Button>
              </p>
            </div>
          </div>
        </div>
      },
      {
        key: '2',
        label: t('yourResponsibility'),
        children: <div>
            <p>{t('yourResponsibilityP1')}</p>
            <ul>
                <li>{t('yourResponsibilityP1LI1')}</li>
                <li>{t('yourResponsibilityP1LI2')}</li>
                <li>{t('yourResponsibilityP1LI3')}</li>
                <li>{t('yourResponsibilityP1LI4')}</li>
                <li>{t('yourResponsibilityP1LI5')}</li>
            </ul>
            <iframe className="faq-video"
              src="https://www.youtube.com/embed/f163-hyAHpU?si=eOnmzeOR0fwIJBdV&rel=0"
              title={t('borrowerResponsibilitiesInLDProcess')}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              frameBorder="0"
              allowFullScreen
            >
            </iframe>
            <p>
              &nbsp;
              <Button
                className="button submit"
                type="primary"
                onClick={() => getVideoTranscriptFile("f163-hyAHpU")}>
                {t('btnVideoTranscript')}
              </Button>
            </p>
        </div>
      },
      {
        key: '3',
        label: t('mipTutorial'),
        children: <div>
            <p>{t('mipTutorialP1')}</p>
        </div>
      },
      
    ];
    
    return <Collapse items={staticItems.concat(dynamicItems)} defaultActiveKey={['1']} activeKey={activeKeyStaticItem} onChange={onChangeStaticItemsCollapse} size="large" />;
    //since Collapse accordion shows accesiblity issues using axe dev tools, we did a work around to acheive the accordion functionality programmatically using react state hook.
  }

export default FaqBody;