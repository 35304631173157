import React, { useContext, useEffect, useState } from 'react';
import { AuthContext, ProfileContext } from '../../App';
import { useTranslation } from 'react-i18next';
import { renderColumnHeader, useTable, useSortBy, usePagination, useExpanded  } from '../Grid';
import { formatDate } from '../../util/Format';
import { get } from '../../util/HttpRequest';
import Waiting from '../Waiting';
import GridSummary from '../GridSummary';
import GridPagination from '../GridPagination';
import { Button } from 'antd';
import { handleGetFileResponse } from '../../util/File';
import { FilePdfIcon, NavAlertIcon } from '../Icons';


class ClaimLetterData {
    letterRequestId: number = 0;
    createdDateUTC: string = "";
    letterDescription: string = "";
    isRead: boolean = false;
};

class ClaimLettersData {
    recordCount: number = 0;
    refreshDate: Date = new Date();
    searchResults: ClaimLetterData[] = [];
};

function ClaimLetters() {
    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");
    const [gridData, setGridData] = useState(new ClaimLettersData());
    const { accessToken, extendSession, user } = useContext(AuthContext);
    const { userProfileLossDraftsClaim } = useContext(ProfileContext);
    const { t } = useTranslation(["ClaimLetters", "DataGrid"]);
    const columnHeaderProps = [
        {
            id: 'createdDateUTC', className: 'datagrid-header-left', sortedDescLabel: t('DataGrid:lblSortedDescending'), sortedAscLabel: t('DataGrid:lblSortedAscending'), unsortedLabel: t('DataGrid:lblUnsorted')
        },
        {
            id: 'letterDescription', className: 'datagrid-header-left', sortedDescLabel: t('DataGrid:lblSortedDescending'), sortedAscLabel: t('DataGrid:lblSortedAscending'), unsortedLabel: t('DataGrid:lblUnsorted')
        }
    ];

    useEffect(() => {
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfileLossDraftsClaim.claimId]);

    function refreshData() {
        setLoading(true);
        getData();
    };

    function getData() {
        extendSession();

        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/ClaimLetters");
        url.searchParams.set("UserId", user.id);
        url.searchParams.set("ClaimId", userProfileLossDraftsClaim.claimId.toString());
        console.log("Fetch Claim Letters from " + url);

        get(url, accessToken)
            .then(response => {
                if (!response.ok) { throw response }
                return response.json() as Promise<ClaimLetterData[]>
            })
            .then(searchResults => {
                const data = {
                    recordCount: searchResults.length,
                    refreshDate: new Date(),
                    searchResults: searchResults
                };
                setGridData(data);
                setErrorMsg("");
                setLoading(false);
            })
            .catch(error => {
                setGridData(new ClaimLettersData());
                setErrorMsg(error.message);
                setLoading(false);
            })
    };

    function getLetterFromAPI(letterRequestId, isDownload) {
        extendSession();

        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/ClaimLetterFile");
        url.searchParams.set("UserId", user.id);
        url.searchParams.set("ClaimId", userProfileLossDraftsClaim.claimId.toString());
        url.searchParams.set("LetterRequestId", letterRequestId.toString());

        console.log("Fetch Claim Letter File from " + url);

        get(url, accessToken)
            .then((response) => {
                handleGetFileResponse(response, isDownload);
                markAsRead(letterRequestId);
            })
            .catch(error => {
                setErrorMsg(error.message);
            });
    };

    function handleViewLetter(event, letterRequestId) {
        event.preventDefault();
        getLetterFromAPI(letterRequestId, false);

    };

    function markAsRead(letterRequestId){
        setGridData((prevData) => {
            const updatedResults = prevData.searchResults.map((letter) =>
              letter.letterRequestId === letterRequestId ? { ...letter, isRead: true } : letter
            );
            return { ...prevData, searchResults: updatedResults };
          });
    };

    function Table({ columns, data }) {
        const pageSize = Number(import.meta.env.VITE_GRID_PAGE_SIZE);
        // Use the state and functions returned from useTable to build your UI
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            //rows,
            prepareRow,
            //visibleColumns,
            page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
            canPreviousPage,
            canNextPage,
            // pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            //setPageSize,
            state: { pageIndex/*, pageSize, expanded*/ },
        } = useTable({
            columns,
            data,
            disableSortRemove: true,
            initialState: {
                pageIndex: 0,
                pageSize: pageSize
            },
        },
            useSortBy,
            useExpanded, // We can useExpanded to track the expanded state for sub components too!
            usePagination
        )

        const filters = <></>;

        const pagination = <GridPagination canPreviousPage={canPreviousPage} canNextPage={canNextPage}
            pageIndex={pageIndex} pageCount={pageCount} gotoPage={gotoPage} previousPage={previousPage}
            nextPage={nextPage} />;

        const summary = <GridSummary pageIndex={pageIndex} pageCount={pageCount} pageSize={pageSize} recordCount={data.length}
            recordType={t('gridRecordType', { count: data.length })} refreshDate={gridData.refreshDate} pagination={pagination} />;

        return (
            <>
                {filters}
                <br />
                {summary}
                <table className="table" role="presentation" {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => {
                            let { ['key']: headerGroupPropsKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
                            return (
                                <tr key={headerGroupPropsKey} {...headerGroupProps}>
                                    {headerGroup.headers.map(column => (
                                        renderColumnHeader(column, columnHeaderProps.find(x => x.id === column.id))
                                    ))}
                                </tr>
                            );
                        })}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <React.Fragment key={{ ...row.getRowProps() }.key}>
                                        <tr className={"row-" + (i % 2 === 0 ? "even" : "odd")}>
                                            {row.cells.map(cell => {
                                                let { ['key']: cellPropsKey, ...cellProps } = cell.getCellProps();
                                                return (
                                                    <td key={cellPropsKey} {...cellProps}>{cell.render('Cell')}</td>
                                                )
                                            })}
                                        </tr>
                                    </React.Fragment>
                                )
                            })
                        }
                    </tbody>
                </table>
            </>
        )
    }

    function renderDataGrid() {
        const columns = [{
                id: 'read status',
                sortable: false,
                Cell: row => (
                    <div className="datagrid-data-center">
                        <span className="action-icon">
                            {
                                row.row.original.isRead === false && (
                                    <NavAlertIcon class="medium-icon color-red" title={t('filterUnread')} />
                                )
                            }
                        </span>
                    </div>
                )
            }, {
            id: 'actions',
            sortable: false,
            Cell: row => (
                <div className="datagrid-data-center">
                    {
                        <Button type="link" className="icon-button" title={t('actionView')}
                            onClick={(e) => handleViewLetter(e, row.row.original.letterRequestId)}
                        >
                            {
                                <FilePdfIcon title={t('actionView')} />
                            }
                        </Button>
                    }

                </div>
            )
        }, {
            Header: () => (<>{t('columnDate')}</>),
            accessor: columnHeaderProps.find(x => x.id === 'createdDateUTC')?.id,
            canSort: true,
            Cell: row => (
                <div className="datagrid-data-left">
                    {
                        formatDate(row.value)
                    }
                </div>
            )
        }, {
            Header: () => (<>{t('columnDescription')}</>),
            accessor: columnHeaderProps.find(x => x.id === 'letterDescription')?.id,
            canSort: true
        }];

        let table = <Table
            columns={columns}
            data={gridData.searchResults}
        />;

        return <div>{table}</div>;
    };
    
    let title = <h2>{t('title')}</h2>;
    let contents = (errorMsg > '') ? <p><em>{errorMsg}</em></p> : loading ? <Waiting size='large' /> : renderDataGrid();
    return <div>{title}<br />{contents}</div>
};

export default ClaimLetters;