import { useContext, useState } from 'react';
import { AuthContext, ProfileContext }  from '../App';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import { PostResponse } from './Models';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SuccessNotification } from './Notification';
import { putForm } from '../util/HttpRequest';

function EmailVerification(props) {
    const [saveErrorMsg, setSaveErrorMsg] = useState("");
    const {accessToken, extendSession, user} = useContext(AuthContext);
    const {refreshUserProfile} = useContext(ProfileContext);
    const { t } = useTranslation(["EmailVerification", "Form"]);

    const ValidationSchema = Yup.object().shape({
        verificationCode: Yup.string()
            .required(t('verificationCodeRequired'))
            .min(8, t("verificationCodeLength"))
            .max(8, t("verificationCodeLength"))
    });

    function handleSubmit(values, setSubmitting) {
        setSubmitting(false);   // MUST invoke this before updating any parent props or the form submit happens twice!
        extendSession();
        
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/VerifyEmail");
        const formData = new FormData();
        formData.append("UserId", user.id);
        formData.append("verificationCode", values.verificationCode);
        console.log("Put VerifyEmail to " + url);

        putForm(url, accessToken, formData)
        .then((response) => {
            if (!response.ok) { throw new Error(response.status + " - " + response.statusText)}
            return response.json() as Promise<PostResponse>;
        })
        .then(response => {
            if (response.success) {
                props.toggleVerifyEmailModal(false);
                refreshUserProfile();
                if (props.allowSuccessNotification) {
                    SuccessNotification(t('lblUpdateSuccessTitle'), t('lblUpdateSuccessBody'));
                }
            }
            else {
                setSaveErrorMsg(t(response.message.charAt(0).toLowerCase() + response.message.substring(1)));
            }
        })
        .catch(() => {
            setSaveErrorMsg(t('saveError'));
        })
    };

    function handleTransition() {
        props.toggleVerifyEmailModal(false);
        props.toggleUpdateEmailModal(true);
    };

    function renderForm() {
        return (
            <Formik
                initialValues ={{
                    verificationCode: ""
                }}
                validationSchema={ValidationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values, setSubmitting); 
                }}
            >
            {   
                ({ values, isSubmitting, handleBlur, setFieldValue, submitForm }) => (
                    <Form>
                        <label htmlFor="verificationCode">{t('lblVerificationCode')}</label>
                        <br />
                        <Field id="verificationCode" name="verificationCode" type="text" className="input" placeholder={t('lblVerificationCode')} data-lpignore="true" />
                        <br />
                        <ErrorMessage name="verificationCode" component="div" className="error" />
                        <br />
                        <Button type="default" className="button" onClick={() => props.toggleVerifyEmailModal(false)}>{t('Form:btnCancel')}</Button>
                        &nbsp;&nbsp;
                        <Button type="primary" className="button submit" disabled={isSubmitting} onClick={submitForm}>{t('btnSubmit')}</Button>
                        <br /><br />
                        <div className="error">{saveErrorMsg}</div>
                    </Form>
                )
            }
            </Formik>
        );
    }
    
    return (
        <Modal open={true} 
               title={t('title')} 
               footer={null}
               onCancel={() => props.toggleVerifyEmailModal(false)}
               centered={true}
               destroyOnClose={true}
        >
            <hr />
            {t('lblInstructions')}
            <br /><br />
            {renderForm()}
            <br />
            <Button type="text" className="link-button" title={t('btnTransitionToPreviousStep')}
                    onClick={handleTransition}
            >
                {t('btnTransitionToPreviousStep')}
            </Button>
        </Modal>
    );
}

export default EmailVerification;
