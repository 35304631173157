import { useContext, useState } from 'react';
import { AuthContext, ProfileContext }  from '../../App';
import { useTranslation } from 'react-i18next';
import { SelectFormField } from '../FormFields';
import { PostResponse } from '../Models';
import { SuccessNotification } from '../Notification';
import { Button, Modal } from 'antd';
import Dropzone, {FileRejection} from 'react-dropzone';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { DeleteIcon } from '../Icons';
import Waiting from '../Waiting';
import { postForm } from '../../util/HttpRequest';

const DropZoneSettings = {
    minFileSizeBytes: 1,
    maxFileSizeBytes: Number(import.meta.env.VITE_MAX_FILE_UPLOAD_SIZE_MB) * 1024 * 1024,
    maxFileSizeString: import.meta.env.VITE_MAX_FILE_UPLOAD_SIZE_MB + " MB",
    fileTypes: JSON.parse(import.meta.env.VITE_VALID_FILE_UPLOAD_TYPES),
    fileTypesString: import.meta.env.VITE_VALID_FILE_UPLOAD_TYPES_DISPLAY
};

const UpconvertBytes = (bytes: number) => {
    let result = bytes.toString() + " bytes";
    if (bytes >= 1048576) {
        result = Math.round(bytes / 1048576).toString() + " MB";
    } else if (bytes > 1024) {
        result = Math.round(bytes / 1024).toString() + " KB";
    }
    return result;
};

function ClaimDocumentUpload(props) {
    const [processing, setProcessing] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [rejectedFiles, setRejectedFiles] = useState(new Array<File>());
    const {accessToken, extendSession, user} = useContext(AuthContext);
    const {userProfileLossDraftsClaim} = useContext(ProfileContext);
    const { t } = useTranslation(["ClaimDocumentUpload", "Form"]);

    const ValidationSchema = Yup.object().shape({
        documentType: Yup.string().required(t("lblDocumentTypeRequired")),
        files: Yup.array().min(1, t("lblFilesRequired"))
    });
    
    function handleSubmit(values, setSubmitting) {
        setSubmitting(false);   // MUST invoke this before updating any parent props or the form submit happens twice!
        setProcessing(true);
        extendSession();
        
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/ClaimDocuments");
        const formData = new FormData();
        formData.append("UserId", user.id);
        formData.append("ClaimId", userProfileLossDraftsClaim.claimId.toString());
        formData.append("DocumentType", values.documentType);
        if (props.documentType &&
            props.documentType > "" &&
            props.documentType === values.documentType &&
            props.claimEventId && 
            props.claimEventId > 0) {
            // if the user kept the documentType as-is and claimEventId is non-zero, it means they came from the tasks screen
            // if so, we should pass the related claimEventId to the CSR so they can relate this document to the claimEventId task
            formData.append("ClaimEventId", props.claimEventId);
        }
        for (let i = 0; i < values.files.length; i++) {
            formData.append("File", values.files[i]);
        }
        console.log("Upload Claim Document (Streaming) to " + url);
        
        postForm(url, accessToken, formData)
        .then((response) => {
            if (!response.ok) { throw new Error(response.status + " - " + response.statusText)}
            return response.json() as Promise<PostResponse>;
        })
        .then(response => {
            if (response.success) {
                props.hideModal();
                props.refreshData();
                SuccessNotification(t('uploadSuccessTitle'), t('uploadSuccessBody'));
            }
            else {
                setErrorMsg(t('uploadFailure'));
            }
        })
        .catch(() => {
            setErrorMsg(t('uploadFailure'));
        })
        .finally(() => {
            setProcessing(false);
        })
    };

    function renderForm() {
        return (
            <Formik
                initialValues ={{
                    documentType: props.documentType || "",
                    files: new Array<File>()
                }}
                validationSchema={ValidationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values, setSubmitting); 
                }}
            >
            {
                ({ values, isSubmitting, setFieldValue, submitForm }) => (
                    <Form>
                        <label id="documentTypeLabel">{t('lblDocumentType')}</label>
                        <br />
                        <Field name="documentType" ariaLabelledBy="documentTypeLabel" component={SelectFormField} options={props.documentTypeSelectOptions.options} disabled={processing} />
                        <ErrorMessage name="documentType" component="div" className="error" />
                        <br />
                        <label htmlFor="files">
                            {t('lblFiles')}
                            &nbsp;&nbsp;&nbsp;
                            {
                                (values.files.length > 0 || rejectedFiles.length > 0) && (
                                    <span 
                                        className={processing ? "icon-button-disabled" : "icon-button"}
                                        tabIndex={0} aria-label={t('lblFilesRemoveAll')} onClick=
                                    {
                                        (event) => {
                                            event.preventDefault();
                                            if (!processing) {
                                                if (values.files.length > 0) {
                                                    setFieldValue("files", new Array<File>());
                                                }
                                                if (rejectedFiles.length > 0) {
                                                    setRejectedFiles(new Array<File>());
                                                }
                                            }
                                        }
                                    }
                                    >
                                        <DeleteIcon title={t('lblFilesRemoveAll')} />
                                    </span>
                                )
                            }
                        </label>
                        <Dropzone
                            accept = { DropZoneSettings.fileTypes }
                            minSize = { DropZoneSettings.minFileSizeBytes }
                            maxSize = { DropZoneSettings.maxFileSizeBytes }
                            onDrop = {
                                (acceptedFiles: File[], fileRejections: FileRejection[]) => {
                                    
                                    if (acceptedFiles.length > 0) {
                                        setFieldValue("files", values.files.concat(acceptedFiles));
                                    }

                                    if (fileRejections.length > 0 || rejectedFiles.length > 0) {
                                        setRejectedFiles(fileRejections.map(x => x.file));
                                    }
                                }
                            }
                            disabled = {processing}
                        >
                        {
                            ({getRootProps, getInputProps}) => (
                                <div>
                                    <aside>
                                    {
                                        values.files.length > 0 && (
                                            <ul className={processing ? "ul-disabled" : undefined}>
                                            {
                                                values.files.map((f: File, idx) => (
                                                    <li key={idx}>
                                                        {f.name} - {UpconvertBytes(f.size)}&nbsp;&nbsp;&nbsp;
                                                        <span
                                                            className={processing ? "icon-button-disabled" : "icon-button"}
                                                            tabIndex={0}
                                                            aria-label={t('lblFilesRemove')} 
                                                            onClick=
                                                        {
                                                            (event) => {
                                                                event.preventDefault();
                                                                if (!processing) {
                                                                    values.files.splice(idx, 1);
                                                                    setFieldValue("files", values.files);
                                                                    if (rejectedFiles.length > 0) {
                                                                        setRejectedFiles(new Array<File>());
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        >
                                                            <DeleteIcon title={t('lblFilesRemove')} />
                                                        </span>
                                                    </li>
                                                ))
                                            }
                                            </ul>
                                        )
                                    }
                                    </aside>
                                    <div className={processing ? "dropzone-disabled" : "dropzone"} {...getRootProps()}>
                                        <input id="files" name="files" {...getInputProps()} />
                                        {t('lblFilesDragAndDrop')}<br /><br />
                                        {
                                            rejectedFiles.length > 0 && (
                                                <ul>
                                                {
                                                    rejectedFiles.map((f: File, idx) => (
                                                        <li key={idx}>
                                                            {f.name} <span className='error'>{t('lblFilesRejected')}</span>
                                                        </li>
                                                    ))
                                                }
                                                </ul>
                                            )
                                        }
                                        <em>{t('lblFilesTypeAndSize', {fileTypes: DropZoneSettings.fileTypesString, fileSize: DropZoneSettings.maxFileSizeString})}</em>
                                    </div>
                                </div>
                            )
                        }
                        </Dropzone>
                        <ErrorMessage name="files" component="div" className="error" />
                        <br />
                        {/* <label htmlFor="fileNote">{t('fileNote')}</label>
                        <br />
                        <Field name="fileNote" component="textarea" className="medium-textarea" placeholder="Note" />
                        <br />
                        <ErrorMessage name="fileNote" component="div" className="error" />
                        <br /> */}
                        <Button type="default" className="button" onClick={() => props.hideModal()}>{t('Form:btnCancel')}</Button>
                        &nbsp;&nbsp;
                        <Button type="primary" className="button submit" disabled={isSubmitting || processing} onClick={submitForm}>{t('title')}</Button>
                        <br /><br />
                        {
                            processing && (<div>{t('lblProcessing')} &nbsp;<Waiting /></div>)
                        }
                        {
                            !processing && (<div className="error">{errorMsg}</div>)
                        }
                    </Form>
                )
            }
            </Formik>
        );
    };

    return (
        <Modal open={true} 
               title={t('title')} 
               footer={null}
               onCancel={() => props.hideModal()}
               centered={true}
               destroyOnClose={true}
        >
            <hr />
            {renderForm()}
        </Modal>
    );
};

export default ClaimDocumentUpload;