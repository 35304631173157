import { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthContext, ProfileContext }  from '../App';
import { Outlet } from 'react-router-dom';
import { Button } from 'antd';
//import Language from './Language';
import SiteBanner from './SiteBanner';
import ClaimsNavbar from './Claim/ClaimsNavbar';
import OtherNavbar from './OtherNavbar';
import Footer from './Footer';
import { useTranslation } from 'react-i18next';
import { MIPLogoIcon } from  './Icons';

function Layout(props)  {
    const {handleLogin, isAuthenticated} = useContext(AuthContext);
    const {userProfileLossDraftsClaims} = useContext(ProfileContext);
    const { t } = useTranslation('Layout');

    function GetLoginButtonTitle() {
        const slashIndex = t('btnLogin').indexOf(' / ');
        if (slashIndex >= 0) {
            // add space around the slash for better readability
            return <>{t('btnLogin').substring(0, slashIndex)} &nbsp;/&nbsp; {t('btnLogin').substring(slashIndex + 2)}</>;
        }
        return <>{t('btnLogin')}</>;
    }

    let contents = 
        <div className="row-container">
            <div id="titlebar" className="column-container space-between">
                {/* <span>{process.env.npm_package_name} v{process.env.npm_package_version}</span> */}
                <MIPLogoIcon  class="logo-icon" title={import.meta.env.VITE_SITE_NAME}  />
              
                {/* {
                    isAuthenticated && <Language />
                } */}
            </div>
            {
                isAuthenticated && (
                <div id="top">
                    <div className="column-container flex-end">
                        <SiteBanner />
                    </div>
                    <Routes>
                        {
                            userProfileLossDraftsClaims.hasClaims && (
                                <Route path="claims" element={
                                    <Navigate to="tasks" replace={true} />
                                } />
                            )
                        }
                        <Route path="claims/*" element={<ClaimsNavbar />} />
                        <Route path="*" element={<OtherNavbar />} />
                    </Routes>
                </div>)
            }
            {
                
                !isAuthenticated && (
                    <div id="top-public">
                        <span className='login'>
                            <Button title={t('btnLogin')} type="primary" size="large" className="button submit" onClick={() => handleLogin()}>
                                {GetLoginButtonTitle()}
                            </Button>
                        </span>
                    </div>
                )
            }
            <div id="content" className={isAuthenticated ? "row-container" : undefined}>
                <Outlet />
            </div>
            <div>
                <Footer />
            </div>
        </div>;
    return (contents);
}

export default Layout;