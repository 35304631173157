import React from 'react';
import { FieldProps } from 'formik';
import Select from 'react-select';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';

const { TextArea } = Input;

const customSelectStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'rgb(252,246,176)' : (state.isFocused ? '#deebff' : 'white'),
      color: 'black'
    })
  }

export const SelectFormField = ({
    options,
    field,
    form,
    ...props
}) => (
  <Select
        aria-labelledby={props.ariaLabelledBy}
        isDisabled={props.disabled}
        name={field.name}
        onBlur={field.onBlur}
        onChange={(option) => {
            form.setFieldTouched(field.name, true);
            form.setFieldValue(field.name, option.value);
        }}
        options={options}
        styles={customSelectStyles}
        value={options ? options.find(option => option.value === field.value) : ''}
    />
);

export const TextAreaFormField: React.FC<TextAreaProps & FieldProps> = ({
    field,
    form,
    ...props
}) => (
    <TextArea 
        {...field} 
        {...props} 
    />
);
