import React, { useContext, useEffect, useState } from 'react';
import { AuthContext, ProfileContext }  from '../App';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { renderColumnHeader, useTable, usePagination, useSortBy, useExpanded } from './Grid';
import Waiting from './Waiting';
import { decrementUnreadAlertCountLabel, setUnreadAlertCountLabel } from '../util/AlertCountHack'
import { formatDate } from '../util/Format';
import { get, putForm } from '../util/HttpRequest'
import { AlertData, AlertsData, PostResponse } from './Models';
import GridSummary from './GridSummary';
import GridPagination from './GridPagination';
import { NavAlertIcon, CaretDownIcon, CaretRightIcon } from './Icons'

function Alerts(props) {
    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");
    const [gridData, setGridData] = useState(new AlertsData());
    const {accessToken, extendSession, user} = useContext(AuthContext);
    const {changeUserProfileLossDraftsClaim, languagePreference, userProfileLossDraftsClaim} = useContext(ProfileContext);
    const { t } = useTranslation(["Alerts", "DataGrid", "Claim"]);
    const claimsNavItems = [
        import.meta.env.VITE_CLAIMS_TAB_TASKS||"",
        import.meta.env.VITE_CLAIMS_TAB_DOCUMENTS||"",
        import.meta.env.VITE_CLAIMS_TAB_TRANSACTIONS||"",
        import.meta.env.VITE_CLAIMS_TAB_INSPECTIONS||"",
        import.meta.env.VITE_CLAIMS_TAB_CONTRACTORS||""
    ];
    const columnHeaderProps = [
        {
            id: 'createdDateUTC', className: 'datagrid-header-left', sortedDescLabel: t('DataGrid:lblSortedDescending'), sortedAscLabel: t('DataGrid:lblSortedAscending'), unsortedLabel: t('DataGrid:lblUnsorted')
        },
        {
            id: 'consumerAlertTemplateDescription', className: 'datagrid-header-left', sortedDescLabel: t('DataGrid:lblSortedDescending'), sortedAscLabel: t('DataGrid:lblSortedAscending'), unsortedLabel: t('DataGrid:lblUnsorted')
        }
    ];

    useEffect (() => {
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languagePreference]);

    function refreshData() {
        setLoading(true);
        getData();
    };

    function markAsRead(consumerAlertIds:string) {
        extendSession();
        
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/MarkConsumerAlertsAsRead");
        const formData = new FormData();
        formData.append("UserId", user.id);
        formData.append("consumerAlertIds", consumerAlertIds);
        console.log("Marking Consumer Alerts as read to " + url);
        
        putForm(url, accessToken, formData)
        .then((response) => {
            if (!response.ok) { throw new Error(response.status + " - " + response.statusText)}
            return response.json() as Promise<PostResponse>;
        })
    };

    function getData() {
        extendSession();
        
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/ConsumerAlerts");
        url.searchParams.set("UserId", user.id);
        console.log("Fetch Claim ConsumerAlerts from " + url);

        get(url, accessToken)
        .then( response => {
            if (!response.ok) { throw response }
            return response.json() as Promise<AlertData[]>
        })
        .then( searchResults => {
            const data = {
                recordCount: searchResults.length, 
                refreshDate: new Date(),
                searchResults: searchResults
            };
            setGridData(data);
            const unreadConsumerAlertIds = searchResults.filter(x => {return x.isRead === false}).map(x => x.consumerAlertId);
            setUnreadAlertCountLabel(unreadConsumerAlertIds.length);
            setErrorMsg("");
        })
        .catch( error => {
            setGridData(new AlertsData());
            setErrorMsg(error.message);
        })
        .finally(() => {
            setLoading(false);
        })
    };

    function handleClaimLinkClick(claimId: number) {
        if (claimId > 0 && claimId !== userProfileLossDraftsClaim.claimId) {
            changeUserProfileLossDraftsClaim(claimId);
        }
    };

    function handleTableRowExpanderClick(row) {
        if ((row.isExpanded === undefined || row.isExpanded === false) && row.original.isRead === false) {
            row.original.isRead = true;
            decrementUnreadAlertCountLabel();
            markAsRead(row.original.consumerAlertId.toString());
        }
        row.toggleRowExpanded();
    };
    
    function handleTableRowKeyDown (event: React.KeyboardEvent<HTMLTableRowElement>, row) {
        event.stopPropagation();
        switch (event.key) {
          case "Enter": 
            handleTableRowExpanderClick(row);
            break;  
          default: break;
        }
    };

    // A simple way to support a renderRowSubComponent is to make a render prop
    // This is NOT part of the React Table API, it's merely a rendering
    // option we are creating for ourselves in our table renderer
    function Table({ columns: userColumns, data, renderRowSubComponent }) {
        const pageSize = Number(import.meta.env.VITE_GRID_PAGE_SIZE);
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            //rows,
            prepareRow,
            visibleColumns,
            page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
            canPreviousPage,
            canNextPage,
            // pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            //setPageSize,
            state: { pageIndex },
        } = useTable(
            {
                columns: userColumns,
                data,
                disableSortRemove: true,
                initialState: { 
                    pageIndex: 0,
                    pageSize: pageSize
                },
            },
            useSortBy,
            useExpanded, // We can useExpanded to track the expanded state for sub components too!
            usePagination
        )

        const pagination = <GridPagination canPreviousPage={canPreviousPage} canNextPage={canNextPage} 
            pageIndex={pageIndex} pageCount={pageCount} gotoPage={gotoPage} previousPage={previousPage}
            nextPage={nextPage} />;
        
        const summary = <GridSummary pageIndex={pageIndex} pageCount={pageCount} pageSize={pageSize} recordCount={data.length} 
            recordType={t('gridRecordType', {count: data.length})} refreshDate={gridData.refreshDate} pagination={pagination} />;
        
        return (
        <>
            {summary}
            <table className="table" role="presentation" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => {
                        let { ['key']: headerGroupPropsKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
                        return (
                            <tr key={headerGroupPropsKey} {...headerGroupProps}>
                                {headerGroup.headers.map(column => (
                                    renderColumnHeader(column, columnHeaderProps.find(x => x.id === column.id))
                                ))}
                            </tr>
                        );
                    })}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row)
                    return (
                        <React.Fragment key={{...row.getRowProps()}.key}>
                        <tr className={"row-" + (i % 2 === 0 ? "even" : "odd") + " pointer"} tabIndex={0} id={row.id} 
                            onKeyDown={(e) => handleTableRowKeyDown(e, row)} 
                            onClick={() => handleTableRowExpanderClick(row)}
                        >
                            {row.cells.map(cell => {
                                let { ['key']: cellPropsKey, ...cellProps } = cell.getCellProps();
                                return (    
                                    <td key={cellPropsKey} {...cellProps}>{cell.render('Cell')}</td>
                                )
                            })}
                        </tr>
                        {/* If the row is in an expanded state, render a row with a column that fills the entire length of the table. */
                            row.isExpanded ? (
                                <tr className="row-detail" tabIndex={0}>
                                    <td colSpan={visibleColumns.length}>
                                        {renderRowSubComponent({ row })}
                                    </td>
                                </tr>
                            ) : null
                        }
                        </React.Fragment>
                    )
                })}
            </tbody>
            </table>
        </>
        )
    }

    const renderRowSubComponent = React.useCallback(
        ({ row }) => {
            let result = <>{row.original.alertText}</>;
            claimsNavItems.some(item => {
                const itemCamelCase = item.charAt(0).toUpperCase() + item.substring(1);
                const itemLinkSearch = "{" + itemCamelCase + "Link}";
                const itemLinkIndex = row.original.alertText?.search(itemLinkSearch);
                if (itemLinkIndex > -1) {
                    const linkUrl = "/claims/" + itemCamelCase;
                    const linkTitle = t("Claim:menu" + itemCamelCase);
                    const claimId = Number(row.original.keyData);
                    result = <>
                        {
                            row.original.alertText.substring(0, itemLinkIndex)
                        }
                        <NavLink to={linkUrl} className={({ isActive }) => "nav-item" + (isActive ? "-current" : "")} onClick={() => handleClaimLinkClick(claimId)}
                        >
                            {linkTitle}
                        </NavLink>
                        {
                            row.original.alertText.substring(itemLinkIndex + itemLinkSearch.length)
                        }
                    </>;
                    return true;
                }
                return false;
            })
            return result;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    function renderDataGrid() {
        const columns = [{
            id: 'expander',
            sortable: false,
            Cell: ({ row, rows, toggleRowExpanded }) => (
                <div className="datagrid-data-left">
                    {
                        row.isExpanded && (
                            <CaretRightIcon title={t('rowCollapse')} />
                        )
                    }
                    {
                        !row.isExpanded && (
                            <CaretDownIcon title={t('rowExpand')} />
                        )
                    }
                </div>
            )
        }, {
            id: 'actions',
            sortable: false,
            Cell: row => (
                <div className="datagrid-data-center">
                    <span className="action-icon">
                        {
                            row.row.original.isRead === false && (
                                <NavAlertIcon class="medium-icon color-red" title={t('filterUnread')} />
                            )
                        }
                    </span>
                </div>
            )
        }, {
            Header: () => (<>{t('columnDate')}</>),
            accessor: columnHeaderProps.find(x => x.id === 'createdDateUTC')?.id,
            canSort: true,
            Cell: row => (
                <div className="datagrid-data-left">
                {
                    formatDate(row.value)
                }
                </div>
            )
        }, {
            Header: () => (<>{t('columnAlert')}</>),
            accessor: columnHeaderProps.find(x => x.id === 'consumerAlertTemplateDescription')?.id,
            canSort: true
        }];

        let table = <Table
            columns={columns}
            data={gridData.searchResults} 
            renderRowSubComponent={renderRowSubComponent}
        />;

        return <div>{table}</div>;
    };

    let title = <h2>{t('title')}</h2>;
    let contents = (errorMsg > '') ? <p><em>{errorMsg}</em></p> : loading ? <Waiting size='large' /> : renderDataGrid();
    return  <div>{title}<br />{contents}</div>;
};

export default Alerts;
