export function handleGetFileResponse(response: Response, isDownload: boolean) {
    if (!response.ok) { throw response }

    let fileName = '';
    if (isDownload && response.headers) {
        const cd  = response.headers.get('content-disposition');
        if (cd && cd > '') {
            fileName = cd.split('filename=')[1].split(';')[0];
            // the expected filename should not contain any characters that need encoding
            // so if the encoded filename is different from the original filename, we may have XSS
            if (fileName !== encodeURIComponent(fileName)) {
                throw response;
            }
        }
    }

    response.blob().then((blob) => {
        if (isDownload) {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}`);
            document.body.appendChild(link);
            link.click();
            if (link.parentNode)
                link.parentNode.removeChild(link);
        } else {
            // cannot set the filename in the new tab
            // https://stackoverflow.com/questions/53548182/can-i-set-the-filename-of-a-pdf-object-displayed-in-chrome
            const url = window.URL.createObjectURL(new Blob([blob], {type: blob.type}));
            window.open(url);
        }
    });
}

