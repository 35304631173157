import { useContext, useEffect, useState } from 'react';
import { AuthContext, ProfileContext }  from '../../App';
import { useTranslation } from 'react-i18next';
import Waiting from '../Waiting';
import { formatAddressMultiLine, formatContact, formatCountry, formatPhone } from '../../util/Format';
import {get} from '../../util/HttpRequest'
import UserRequest from './UserRequest';
import { Button } from 'antd';
import { CollapseIcon, ExpandIcon, RequestInspectionIcon } from '../Icons'

class ClaimDetailsData {
    borrowerName: string = "";
    coBorrowerName: string = "";
    claimId: number = 0;
    claimProfileCode: string = "";
    claimStatusCode: string = "";
    claimNumber: string = "";
    propertyAddress1: string = "";
    propertyAddress2: string = "";
    propertyCity: string = "";
    propertyState: string = "";
    propertyPostalCode: string = "";
    propertyCountryISO: string = "";
    contactName: string = "";
    contactAddress1: string = "";
    contactAddress2: string = "";
    contactCity: string = "";
    contactState: string = "";
    contactPostalCode: string = "";
    contactCountryISO: string = "";
    contactEmailAddress: string = "";
    contactPhoneNumber: string = "";
    isLoaded: boolean = false;
};

function ClaimDetails(props) {
    const [errorMsg, setErrorMsg] = useState("");
    const [claimDetailsData, setClaimDetailsData] = useState(new ClaimDetailsData());
    const [userRequestModal, setUserRequestModal] = useState("");
    const [displayCollapsableData, setDisplayCollapsableData] = useState(false);
    const {accessToken, extendSession, user} = useContext(AuthContext);
    const {userProfileLossDraftsClaim} = useContext(ProfileContext);
    const { t } = useTranslation("ClaimDetails");

    useEffect (() => {
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfileLossDraftsClaim.claimId]);

    function refreshData() {
        props.setLoadingDetails(true);
        getData();
    };

    function getData() {
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/ClaimDetail");
        url.searchParams.set("UserId", user.id);
        url.searchParams.set("ClaimId", userProfileLossDraftsClaim.claimId.toString());

        console.log("Fetch Claim Details from " + url);
        
        get(url, accessToken)
        .then( response => {
            if (!response.ok) { throw response }
            return response.json() as Promise<ClaimDetailsData>
        })
        .then( responseData => {
            setClaimDetailsData(responseData);
            setErrorMsg("");
            props.setIsClaimActive(['OPEN', 'REOPENED'].includes(responseData.claimStatusCode.trim().toUpperCase()));
            props.setLoadingDetails(false);
        })
        .catch( error => {
            setClaimDetailsData(new ClaimDetailsData());
            setErrorMsg(error.message);
            props.setLoadingDetails(false);
        })
    };

    function toggleUserRequestModal(value: string) {
        extendSession();
        setUserRequestModal(value);
    }

    function toggleDisplay(event, value) {
        event.preventDefault();
        document.querySelectorAll('.collapsable-data').forEach(x => x.classList.toggle('collapsed'));
        document.querySelectorAll('.collapsable-link').forEach(x => x.classList.toggle('collapsed'));
        setDisplayCollapsableData(value);
    };

    function renderData() {
        return (
            <div>
                <div className="column-container space-evenly collapsable-data">
                    <div className="row-container width-medium">
                        <div className="column-container"  > 
                            <div className="key">{t('lblBorrower')}</div>
                            <div className="value">{props.loadingDetails ? <Waiting size="small" /> : claimDetailsData.borrowerName}</div>
                        </div>
                        <div className="column-container" >
                            <div className="key even">{t('lblCoBorrower')}</div>
                            <div className="value even ">{props.loadingDetails ? <Waiting size="small" /> : claimDetailsData.coBorrowerName}</div>
                        </div>
                        <div className="column-container" >
                            <div className="key">{t('lblPropertyAddress')}</div>
                            <div className="value multiline">
                            {
                                props.loadingDetails ? <><Waiting size="small" /><br /><br /></> : 
                                formatAddressMultiLine(
                                    [claimDetailsData.propertyAddress1, claimDetailsData.propertyAddress2], 
                                    [claimDetailsData.propertyCity, claimDetailsData.propertyState, claimDetailsData.propertyPostalCode],
                                    [claimDetailsData.propertyCountryISO]
                                )
                            }
                            </div>
                        </div>
                    </div>
                    <div className="filler-column">&nbsp;</div>
                    <div className="row-container width-medium">
                        <div className="column-container" >
                            <div className="key">{t('lblClaimProfile')}</div>
                            <div className="value">{props.loadingDetails ? <Waiting size="small" /> : claimDetailsData.claimProfileCode}</div>
                        </div>
                        <div className="column-container" >
                            <div className="key even">{t('lblClaimStatus')}</div>
                            <div className="value even">{props.loadingDetails ? <Waiting size="small" /> : claimDetailsData.claimStatusCode}</div>
                        </div>
                        <div className="column-container" >
                            <div className="key">{t('lblClaimNumber')}</div>
                            <div className="value">{props.loadingDetails ? <Waiting size="small" /> : claimDetailsData.claimNumber}</div>
                        </div>
                        <div className="column-container"  > 
                            <div className="key even">{t('lblClaimContact')}</div>
                            <div className="value even multiline">
                                {
                                    props.loadingDetails ? <Waiting size="small" /> : formatContact(
                                        claimDetailsData.contactName, 
                                        formatAddressMultiLine([claimDetailsData.contactAddress1, claimDetailsData.contactAddress2],[claimDetailsData.contactCity, claimDetailsData.contactState, claimDetailsData.contactPostalCode],[formatCountry(claimDetailsData.contactCountryISO)]),
                                        claimDetailsData.contactEmailAddress,
                                        formatPhone("", "", claimDetailsData.contactPhoneNumber, "")
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="filler-column">&nbsp;</div>
                    <div className="row-container width-small">
                        {
                            props.isClaimActive && 
                            (
                                <>
                                    <Button 
                                        className="button submit" 
                                        icon={<RequestInspectionIcon class="svg-button-icon" title={t('btnClaimInspectionRequest')} />}
                                        type="primary" 
                                        onClick={() => toggleUserRequestModal("InspectionRequest")}>{t('btnClaimInspectionRequest')}
                                    </Button>
                                    <br />
                                </>
                            )
                        }
                    </div>
                </div>
                <div className="column-container flex-end collapsable-link">
                    <Button type="text" className="link-button"
                        onClick={(e) => toggleDisplay(e, !displayCollapsableData)}
                    >
                        {
                            displayCollapsableData && (
                                <CollapseIcon class="small-icon" title={t('sectionCollapse')} />
                            )
                        }
                        {
                            !displayCollapsableData && (
                                <ExpandIcon class="small-icon" title={t('sectionExpand')} />
                            )
                        }
                        {displayCollapsableData === true ? t('sectionExpand') : t('sectionCollapse')}
                    </Button>
                </div>
                <hr className="divider" />
                {
                    userRequestModal > "" && 
                    (
                        <UserRequest 
                            userRequestType={userRequestModal}
                            toggleUserRequestModal={toggleUserRequestModal} 
                        />
                    )
                }
            </div>
        );
    };

    let contents = (errorMsg > '') ? <p><em>{errorMsg}</em></p> : renderData();
    return <div>{contents}</div>
};

export default ClaimDetails;