import * as React from "react";
const SvgLetter = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", id: "letter_svg__Layer_1", "data-name": "Layer 1", viewBox: "0 0 250 250", width: "1em", height: "1em", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("style", null, ".letter_svg__cls-3,.letter_svg__cls-4{stroke:#fff;stroke-miterlimit:10}.letter_svg__cls-4{stroke-width:10px}.letter_svg__cls-3{stroke-width:11px}.letter_svg__cls-3,.letter_svg__cls-4{fill:none}")), /* @__PURE__ */ React.createElement("path", { d: "M0 0h250v250H0z", style: {
  fill: "#abc7ca"
} }), /* @__PURE__ */ React.createElement("path", { d: "M30.72 175.26 80.93 250H250V134.85L219.28 78.1z", style: {
  fill: "#8eb6be"
} }), /* @__PURE__ */ React.createElement("path", { d: "M35.72 84.1h178.56v87.15H35.72z", style: {
  stroke: "#fff",
  strokeMiterlimit: 10,
  strokeWidth: 10,
  fill: "#8eb6be"
} }), /* @__PURE__ */ React.createElement("path", { d: "m38.75 86.76 87.62 50.68M210.62 87.27l-88.77 50.15", className: "letter_svg__cls-4" }), /* @__PURE__ */ React.createElement("path", { d: "m99.79 121.08-60.78 47.45M148.28 118.98l67.63 52.27", className: "letter_svg__cls-3" }));
export default SvgLetter;
